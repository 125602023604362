import PageTemplate from "../../components/page-template/page-template";
import {useOktaAuth} from '@okta/okta-react';
import BookingService from "../../services/booking-service";
import {Booking} from "../../models/booking";
import {useEffect, useRef, useState} from "react";
import {ReactSVG} from 'react-svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import settings from '../../assets/icons/settings.svg';
import arrowUpRightFromSquare from '../../assets/icons/arrow-up-right-from-square.svg';
import { MouseEvent } from 'react';

import './view-booking-page.scss';
import BookingDetails from "../../components/booking-details/booking-details";
import BookingFilter from "../../components/booking-filter/booking-filter";
import {BookingState} from "../../models/BookingState";
import { UserRole } from "../../models/UserRole";
import UserService from "../../services/user-service";
import { useNavigate } from "react-router-dom";

function ViewBookingsPage() {
    const navigate = useNavigate();
    
    const { authState } = useOktaAuth();
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [numberOfBooking, setNumberOfBooking] = useState(0);
    const [subsidiaryName, setSubsidiaryName] = useState<String>("");

    const [countFilterResult, setCountFilterResult] = useState(0);

    const [page, setPage] = useState(0);

    const [hasMore, setHasMore] = useState(true);
    const elementRef = useRef(null);

    const pageRef = useRef(page);

    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [isPopUpDetail, setIsPopUpDetail] = useState(false);
    const [isPopUpDetailName, setIsPopUpDetailName] = useState(false);
    const [isPopUpDetailRetailerReference, setIsPopUpDetaiRetailerReference] = useState(false);
    const [isPopUpDetailAffiliates, setIsPopUpDetailAffiliates] = useState(false);
    const [isPopUpDetailBookingDate, setIsPopUpDetailBookingDate] = useState(false);
    const [isPopUpDetailBookingHour, setIsPopUpDetailBookingHour] = useState(false);
    const [isPopUpDetailGuestNumber, setIsPopUpDetailGuestNumber] = useState(false);
    const [isPopUpDetailStatus, setIsPopUpDetailStatus] = useState(false);

    const [titleName, setTitleName] = useState("");

    const [isShowButton, setIsShowButton] = useState(true);

    const [filterValues, setFilterValues] = useState<Record<string, number>[]>([]);

    const [filterFieldAndValue, setFilterFieldAndValue] = useState<Map<string, string[]>>(new Map());

    const [order, setOrder] = useState("asc");

    const [isFilter, setIsFilter] = useState(false);

    const [filtersObject, setFiltersObject] = useState<{ [key: string]: any }>({});

    const [isArrowDown, setIsArrowDown] = useState(true);

    const [userRole, setUserRole] = useState<UserRole>(UserRole.USER);
    const [userSubsidiary, setUserSubsidiary] = useState<string>("");
    const [isFilterDeleteValue, setIsFilterDeleteValue] = useState<boolean>(false);

    const [numberOfFieldsSelected, setNumberOfFieldsSelected] = useState<number>(0);
    interface SelectedOption {
        Name: string[];
        RetailerReference: string[];
        Affiliate: string[];
        BookingDate: string[];
        Hour: string[];
        NumberOfGuests: string[];
        Status: string[];
    }  

    type SelectedOptionKey = keyof SelectedOption;

    const [selectedOption, setSelectedOption] = useState<SelectedOption>({
        Name: [],
        RetailerReference: [],
        Affiliate: [],
        BookingDate: [],
        Hour: [],
        NumberOfGuests: [],
        Status: [],
    });

    const handleSort = () => {
        setIsArrowDown(!isArrowDown)
        const newOrder = order === "asc" ? "desc" : "asc";
        setOrder(newOrder);
        setPage(0);
        elementRef.current = null;
        pageRef.current = 0;
        setHasMore(false);
        if (!isFilter) {
            setBookings([]);
            fetchMoreItems(newOrder);
        } else {
            filterBooking(newOrder)
        }
    };

    function onIntersection(entries: any) {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore) {
            if (!isFilter) {
                fetchMoreItems(order);
            } else {
                filterBooking(order)
            }
        }
    }

    const fetchInfos = async () => {
        try {
            let affiliate = userSubsidiary.replace("Rolex", "").trim();

            setSubsidiaryName(affiliate);

            const totalCount = await BookingService.count();
            if(totalCount === -1) {
                navigate("/");
            }
            setNumberOfBooking(totalCount);
        } catch (error) {
            navigate('/');
            console.error("Error fetching infos", error);
        }
    };
    const fetchMoreItems = async (currentOrder: string) => {
        try {
            const params = {
                params: {
                    page: pageRef.current,
                    size: 10,
                    order: currentOrder
                },
            };

            const result: Booking[] = await BookingService.getAllBooking(params);
            if (result.length === 0) {
                setHasMore(false);
            } else {
                setBookings((prevBookings: Booking[]) => [...prevBookings, ...result]);
                setHasMore(true);

                setPage((prevPage) => prevPage + 1);
            }
        } catch (error: any) {
            navigate('/');
            console.error("Error fetching more items", error);
        }
    };

    useEffect(() => {
        if(isFilterDeleteValue) {
            handleIsPopUpDetail();
        }
    }, [filterFieldAndValue, selectedOption]);

    useEffect(() => {
        fetchInfos();
        getCurrentUser();
    }, [bookings]);

    useEffect(() => {
        pageRef.current = page;
    }, [page, isFilter, filtersObject]);

    useEffect(() => {
        const observer = new IntersectionObserver(onIntersection);
        if (observer && elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [hasMore]);

    if (!authState || !authState.isAuthenticated) {
        return null;
    }
    const userName = authState.idToken?.claims.name;

    const formatDate = (inputDate: Date) => {
        return new Date(inputDate).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const exportExcel = async () => {
        await BookingService.exportExcel(filtersObject);
    };

    /************************* Handle Filter  ***********************************************************/


    const handleBookingDate = async () => {
        try{
            filtersObject["size"] = 1;
            const params = {params: filtersObject};
            const result: Booking[] = await BookingService.getAllBooking(params);

            const counts: Record<string, number> = {};

            for (const booking of result) {
                const bookingDate = new Date(booking.bookingDate);
                bookingDate.setDate(bookingDate.getDate());
                const formattedDate = bookingDate.toISOString().split('T')[0];
                counts[formattedDate] = (counts[formattedDate] || 0) + 1;
            }

            const nameResult = Object.entries(counts).map(([key, value]) => ({[key]: value}));
            nameResult.sort((a, b) => new Date(Object.keys(a)[0]).getTime() - new Date(Object.keys(b)[0]).getTime());

            setFilterValues(nameResult);
        } catch(error) {
            navigate('/');
            console.error('Error fetching Bookings', error);
        }
    }   

    const handlePopUpDetail = async (field: keyof Booking) => {
        try{
            filtersObject["size"] = 1;
            const params = {params: filtersObject};
            const result: Booking[] = await BookingService.getAllBooking(params);
            const counts: Record<string, number> = {};
            let values;
            if (field === "firstnameBeneficiary") {
                values = result.map(x => ({
                    firstName: x.firstnameBeneficiary as string,
                    lastName: x.lastnameBeneficiary as string,
                }));
                const nameResult = values.reduce((acc: Record<string, number>[], currentValue) => {
                    const key = `${currentValue.firstName} ${currentValue.lastName}`;
                    const existingIndex = acc.findIndex(item => item[key] !== undefined);
                
                    if (existingIndex !== -1) {
                        acc[existingIndex][key]++;
                    } else {
                        const newItem: Record<string, number> = { [key]: 1 };
                        acc.push(newItem);
                    }
                
                    return acc;
                }, []);
                setFilterValues(nameResult);
            } else {
                values = result.map(x => x[field] as string | number | Date);
                for (const value of values) {
                    counts[String(value)] = (counts[String(value)] || 0) + 1;
                }
        
                const nameResult = Object.entries(counts).filter(([key, _]) => key !== '').map(([key, value]) => ({[key]: value}));
                nameResult.sort((a: any, b: any) => {
                    const aKey = Object.keys(a)[0];
                    const bKey = Object.keys(b)[0];
            
                    if (!isNaN(Number(aKey)) && !isNaN(Number(bKey))) {
                        return Number(aKey) - Number(bKey);
                    }
                    
                    return String(aKey).localeCompare(String(bKey));
                });   
                setFilterValues(nameResult);
            }
        } catch(error) {
            navigate('/');
            console.error('Error fetching Bookings', error);
        }
    }

    const handleFilter = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen);

        setIsPopUpDetail(false);

        setIsShowButton(true);

        setFilterValues([])
    };

    const handlePopUpDetailName = async () => {
        setTitleName("Name");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);

        handlePopUpDetail("firstnameBeneficiary");
    }

    const handlePopUpDetailRetailerReference = async () => {
        setTitleName("Retailer Reference");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);

        handlePopUpDetail("retailerReferenceBeneficiary");
    }

    const handlePopUpDetailAffiliate = async () => {
        setTitleName("Affiliate");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(false);

        handlePopUpDetail("subsidiaryBeneficiary");
    }

    const handlePopUpDetailBookingDate = async () => {
        setTitleName("Booking date");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);
        handleBookingDate();
    }

    const handlePopUpDetailNumberOfGuests = async () => {
        setTitleName("Number of guests");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);

        handlePopUpDetail("numberOfGuests");
    }

    const handlePopUpDetailHour = async () => {
        setTitleName("Hour");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);

        handlePopUpDetail("bookingHour");

    }

    const handlePopUpStatus = async () => {
        setTitleName("Status");
        setIsPopUpDetail(!isPopUpDetail);
        setIsShowButton(!isShowButton);

        handlePopUpDetail("state");
    }

    const filterBooking = async (currentOrder: string) => {
        try {
            filtersObject["page"] = pageRef.current;
            filtersObject["size"] = 10;
            filtersObject["order"] = currentOrder;
            const params = {params: filtersObject};
            const result: Booking[] = await BookingService.getAllBooking(params);
            if (result.length === 0) {
                if (filtersObject.page === 0) {
                    setBookings([]);
                }
                setHasMore(false);
            } else {
                if(countFilterResult === 0 || (countFilterResult > result.length && pageRef.current === 0)) {
                    setCountFilterResult(result.length);
                } else {
                    setCountFilterResult(result.length);
                }
                if (filtersObject.page >= 1) {
                    setBookings((prevBookings: Booking[]) => [...prevBookings, ...result]);
                    if(countFilterResult < result.length) {
                        setCountFilterResult(result.length);
                    } else {
                        setCountFilterResult(result.length + countFilterResult);
                    }
                } else {
                    const resultWithKey = result.map((booking, index) => ({...booking, key: index}));
                    setBookings(resultWithKey);
                }

                setHasMore(true);
                setPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            navigate('/');
            console.error("Error fetching more items", error);
        }
    }

    const handleIconPopFilter = <Key extends keyof SelectedOption>(key: Key) => {
        const isNotEmpty = selectedOption[key] && selectedOption[key].length > 0;
        switch (key) {
            case "Name":
                setIsPopUpDetailName(isNotEmpty);
                break;
            case "RetailerReference":
                setIsPopUpDetaiRetailerReference(isNotEmpty);
                break;
            case "Affiliate":
                setIsPopUpDetailAffiliates(isNotEmpty);
                break;
            case "BookingDate":
                setIsPopUpDetailBookingDate(isNotEmpty);
                break;
            case "Hour":
                setIsPopUpDetailBookingHour(isNotEmpty);
                break;
            case "NumberOfGuests":
                setIsPopUpDetailGuestNumber(isNotEmpty);
                break;
            case "Status":
                setIsPopUpDetailStatus(isNotEmpty);
                break;
            default:
                break;
        }
    };

    const handleIsPopUpDetail = async () => {
        setIsPopUpDetail(false);
        setPage(0);
        elementRef.current = null;
        pageRef.current = 0;
        setHasMore(false);
        setIsShowButton(true);
        setIsFilter(true);

        handleIconPopFilter("Name");
        handleIconPopFilter("Affiliate");
        handleIconPopFilter("RetailerReference");
        handleIconPopFilter("BookingDate");
        handleIconPopFilter("Hour");
        handleIconPopFilter("NumberOfGuests");
        handleIconPopFilter("Status");
        const sizesArray = Array.from(filterFieldAndValue.entries()).map(([_, values]) => values.length);
        const totalSize = sizesArray.reduce((acc, size) => acc + size, 0);
        setNumberOfFieldsSelected(totalSize);
        
        if (filterFieldAndValue.size > 0) {
            setBookings([]);
            filterFieldAndValue.forEach((values, key) => {
                switch (key) {
                    case "Status":
                        const stateValues: BookingState[] = values.map((value: string) => {
                            switch (value) {
                                case BookingState.PENDING:
                                    return BookingState.PENDING;
                                case BookingState.REFUSE:
                                    return BookingState.REFUSE;
                                case BookingState.VALIDATE:
                                    return BookingState.VALIDATE;
                                default:
                                    return BookingState.PENDING;
                            }
                        });
                        filtersObject["state"] = stateValues;
                        break;
                    case "Hour":
                        filtersObject["bookingHour"] = values;
                        break;
                    case "NumberOfGuests":
                        filtersObject["numberOfGuests"] = values.map((value: string) => parseInt(value));
                        break;
                    case "BookingDate":
                        const parsedDates = values.map((val) => {
                            const parsedDate = new Date(val);
                            
                            const isoString = parsedDate.toISOString();
                          
                            const datePart = isoString.substring(0, 10);
                          
                            const finalDateString = datePart + 'T00:00Z';
                          
                            return finalDateString;
                          });
                        filtersObject["bookingDate"] = parsedDates;
                        break;
                    case "Affiliate":
                        filtersObject["subsidiaryBeneficiary"] = values;
                        break;
                    case "Name":
                        filtersObject["name"] = values;
                        break;
                    case "RetailerReference":
                        filtersObject["retailerReference"] = values;
                        break;
                    default:
                        break;
                }
            });
        }
        filterBooking(order);
    };
    
    const handleRemoveBooking = (bookingId: string) => {
        try {        
            const updatedBookings = bookings.filter(booking => booking.id !== bookingId);
            setBookings(updatedBookings);  
        } catch (error) {
            console.error('Error during the cancellation of the reservation :', error);
        }
    };

    const handleDeleteAllfilters = async () => {
        setIsFilter(false);

        setSelectedOption({
            Name: [],
            RetailerReference: [],
            Affiliate: [],
            BookingDate: [],
            Hour: [],
            NumberOfGuests: [],
            Status: [],
        })
        setFiltersObject({});

        setFilterFieldAndValue(new Map());
        setBookings([]);
        setCountFilterResult(0);
        setNumberOfFieldsSelected(0);

        setIsPopUpDetailName(false);
        setIsPopUpDetaiRetailerReference(false);
        setIsPopUpDetailAffiliates(false);
        setIsPopUpDetailBookingDate(false);
        setIsPopUpDetailBookingHour(false);
        setIsPopUpDetailStatus(false);
        setIsPopUpDetailGuestNumber(false);

        setPage(0);
        elementRef.current = null;
        pageRef.current = 0;

        setTimeout(() => {
            fetchMoreItems(order);
        }, 1000);
    }

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFilterDeleteValue(false);
        const value = event.target.value;
        const fieldName = event.target.name as SelectedOptionKey
        setSelectedOption((prevSelectedOption) => {            
            const updatedOptions = { ...prevSelectedOption };
            if (!updatedOptions[fieldName]) {
                updatedOptions[fieldName] = [];
            }
            if (value === "SelectAll" && updatedOptions[fieldName][0] !== "SelectAll") {
                updatedOptions[fieldName] = [];
                updatedOptions[fieldName] = [...updatedOptions[fieldName], value];

            } else {
                const indexSelectAll = updatedOptions[fieldName].indexOf("SelectAll");
                if (indexSelectAll !== -1) {
                    updatedOptions[fieldName] = updatedOptions[fieldName].filter((item) => item !== "SelectAll");
                }
    
                const index = updatedOptions[fieldName].indexOf(value);
    
                if (index === -1) {
                    updatedOptions[fieldName] = [...updatedOptions[fieldName], value];
                } else {
                    updatedOptions[fieldName] = [
                        ...updatedOptions[fieldName].slice(0, index),
                        ...updatedOptions[fieldName].slice(index + 1),
                    ];
                }
            }
    
            return updatedOptions;
        });
        setFilterFieldAndValue((prevFilterValues) => {
            const newFilterFieldAndValue = new Map(prevFilterValues);
        
            if (value !== "SelectAll") {
                const existingValues = newFilterFieldAndValue.get(fieldName) || [];
                if (!existingValues.includes(value)) {
                    const updatedValues = [...existingValues, value];
                    newFilterFieldAndValue.set(fieldName, updatedValues);
                }
                else {
                    const updatedValues = existingValues.filter((item) => item !== value);
                    newFilterFieldAndValue.set(fieldName, updatedValues);
                }
            } else {
                newFilterFieldAndValue.delete(fieldName);
            }
        
            return newFilterFieldAndValue;
        });
   
        if (value === "SelectAll") {
            switch (fieldName) {
                case "Name":
                    delete filtersObject.name;
                    break;
                case "RetailerReference":
                    delete filtersObject.RetailerReference;
                    break;
                case "Affiliate":
                    delete filtersObject.subsidiaryBeneficiary;
                    break;
                case "BookingDate":
                    delete filtersObject.bookingDate;
                    break;
                case "Hour":
                    delete filtersObject.bookingHour;
                    break;
                case "NumberOfGuests":
                    delete filtersObject.numberOfGuests;
                    break;
                case "Status":
                    delete filtersObject.state;
                    break;
            }
            const newFilterFieldAndValue = new Map(filterFieldAndValue);
            newFilterFieldAndValue.delete(fieldName);
            setFilterFieldAndValue(newFilterFieldAndValue);
        }
    };
    
    const handlePopupClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      };

    const getCurrentUser = async() => {
        try {
            let result = await UserService.getCurrentUser();
            if(result !== null) {
                setUserRole(result.role);
                setUserSubsidiary(result.subsidiary.name);
            } else {
                navigate('/');
            }
        } catch(error) {
            navigate('/');
            console.error("Error getting current user", error);
        }      
    } 
    const handleTitleUserName = () => {
        const firstname = userName?.split(' ')[0]

        if (userRole !== UserRole.ADMIN) {
            return firstname + ' User';
        } else {
            return firstname + ' Admin';
        }
    }

    const handleRemoveField = (key: string, value: string) => {
        setIsFilterDeleteValue(true);
        setFiltersObject({});
        setFilterFieldAndValue(prevState => {
            const newMap = new Map(prevState);    
            if (newMap.has(key)) {
                const values = newMap.get(key) || [];
                const updatedValues = values.filter(v => v !== value);
    
                if (updatedValues.length === 0) {
                    newMap.delete(key);
                } else {
                    newMap.set(key, updatedValues);
                }
            }
            return newMap;
        });
        let fieldName = key as SelectedOptionKey;
        setSelectedOption((prevSelectedOption) => {            
            const updatedOptions = { ...prevSelectedOption };
            if (!updatedOptions[fieldName]) {
                updatedOptions[fieldName] = [];
            }
            const indexSelectAll = updatedOptions[fieldName].indexOf("SelectAll");
            if (indexSelectAll !== -1) {
                updatedOptions[fieldName] = updatedOptions[fieldName].filter((item) => item !== "SelectAll");
            }

            const index = updatedOptions[fieldName].indexOf(value);

            if (index === -1) {
                updatedOptions[fieldName] = [...updatedOptions[fieldName], value];
            } else {
                updatedOptions[fieldName] = [
                    ...updatedOptions[fieldName].slice(0, index),
                    ...updatedOptions[fieldName].slice(index + 1),
                ];
            }    
            return updatedOptions;
        });
    };

    return (
        <PageTemplate>
            <div className="header-container">
                <div className="header-container-first">
                    <span className="header-name">{handleTitleUserName()}</span>
                    <span className="header-name-2">{subsidiaryName} Affiliate</span>
                </div>
                <div className="header-booking-number">
                    <span className="booking-number">{numberOfBooking}</span>
                    <span className="booking-text">Bookings</span>
                </div>
            </div>
            <div className="btns-header">
                <button className="btn-recent" onClick={handleSort}>
                    <span className="btn-recent-icon">
                    {isArrowDown ? (
                        <ReactSVG src={arrowDown} />
                    ) : (
                        <ReactSVG src={arrowUp} />
                    )}
                    </span>
                    <span className="btn-recent-text">Recent</span>
                </button>
                <div onClick={handlePopupClick}>
                    <button className="btns-filter" onClick={handleFilter}>
                        <span className="btn-filter-icon"><ReactSVG src={settings}/></span>
                        <span className="btn-filter-text">Filters</span>
                        {numberOfFieldsSelected > 0 && <span className="btn-filter-number-selected">{numberOfFieldsSelected}</span>}
                    </button>
                </div>
                {userRole === UserRole.ADMIN && (
                    <button className="btns-export" onClick={exportExcel}>
                        <ReactSVG src={arrowUpRightFromSquare}/>
                    </button>
                )}
            </div>
            <hr className="bar-decor" />
            {bookings.map((booking) => (
                <div key={booking.id}>
                    <BookingDetails
                        bookingId={booking.id}
                        bookingDate={formatDate(booking.bookingDate)}
                        bookingGuestNumber={booking.numberOfGuests}
                        bookingStatus={booking.state}
                        userName={booking.firstnameBeneficiary !== '' ? booking.firstnameBeneficiary + ' ' + booking.lastnameBeneficiary : userName?.split(' ')[0] + " Admin" as string}
                        bookingTime={booking.bookingHour as string}
                        subsidiary={booking.subsidiaryBeneficiary}
                        userRole={userRole as UserRole}
                        handleRemoveBooking={handleRemoveBooking}
                    />
                </div>
            ))}
            {isFilterPopupOpen && (
                <BookingFilter name={titleName}
                               isPopUpDetail={isPopUpDetail}
                               isShowButton={isShowButton}
                               handleFilter={handleFilter}
                               filterValue={filterValues}
                               handlePopUpDetailName={handlePopUpDetailName}
                               handlePopUpDetailRetailerReference={handlePopUpDetailRetailerReference}
                               handlePopUpDetailAffiliate={handlePopUpDetailAffiliate}
                               handlePopUpDetailBookingDate={handlePopUpDetailBookingDate}
                               handlePopUpDetailNumberOfGuests={handlePopUpDetailNumberOfGuests}
                               handlePopUpDetailHour={handlePopUpDetailHour}
                               handlePopUpStatus={handlePopUpStatus}
                               selectedOption={selectedOption}
                               handleOptionChange={handleOptionChange}
                               handleIsPopUpDetail={handleIsPopUpDetail}
                               countFilterResult={countFilterResult}
                               handleDeleteAllFilters={handleDeleteAllfilters}
                               isPopUpDetailName={isPopUpDetailName}
                               isPopUpDetailRetailerReference={isPopUpDetailRetailerReference}
                               isPopUpDetailAffiliates={isPopUpDetailAffiliates}
                               isPopUpDetailBookingDate={isPopUpDetailBookingDate}
                               isPopUpDetailBookingHour={isPopUpDetailBookingHour}
                               isPopUpDetailGuestNumber={isPopUpDetailGuestNumber}
                               isPopUpDetailStatus={isPopUpDetailStatus}
                               filterFieldAndValue={filterFieldAndValue}
                               handleRemoveField={handleRemoveField}
                />
            )}
            {hasMore && <div ref={elementRef}></div>}
        </PageTemplate>
    );
}

export default ViewBookingsPage;